.bg {
    background-image: url(../../assets/images/spacelot.png);
    background-size: cover;
    background-position: top center;
    min-height: 100vh;
    padding: 10px;
}

.card {
    /* padding: 180px 10px 20px 10px; */
    /* margin: auto; */
    max-width: auto;
    /* width: 40%; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ant-card-bordered {
    border: 0px solid;
}

.ant-card {
    background: transparent;
}

.ant-card-body {
    min-height: 80px;
}

.header-login {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: -40px;
    padding: 20px 0;
    background: linear-gradient(45deg, #6F7DBE, #B0B8DB);
    text-align: center;
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #ffffff;
}

h3 {
    margin-top: 10px;
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
}

.icon-line {
    margin-top: 15px;
    text-align: center;
}

.btn {
    background-color: #160f03;
    color: #ffffff;
    border-color: #3388FF;
    min-height: 50px;
    border-radius: 25px;
}

.btn:hover {
    background-color: #ffffff;
    color: #3388FF;
    border-color: #ffffff;
}

.real-input {
    border-radius: 25px;
    min-height: 50px;
}

.real-input,
.ant-input-affix-wrapper>input.ant-input {
    background-color: #160f03;
    color: #ffffff;
    border-color: #3388FF;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    /* background-color: #160f03; */
    /* color: #ffffff; */
    border-color: #3388FF;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background: transparent;
}

.footer {
    margin: auto;
}