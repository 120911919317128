.recommend-section {
    padding: 10px;
    background-color: #f4fcff;
    margin-bottom: 5px;
    /* position: relative; */
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.rate-section {
    padding: 10px;
    background-color: #f4fcff;
    position: relative;
    margin-bottom: 5px;
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.bet-section {
    padding: 10px;
    background-color: #f4fcff;
    /* position: relative; */
    margin-bottom: 5px;
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.bill-section {
    padding: 10px;
    background-color: #f4fcff;
    /* position: relative; */
    margin-bottom: 10px;
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.box-inside {
    margin: 10px 0px
}

.divider-inside {
    margin: 5px 0px
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}

.inactive-bill {
    background-color: #FF9999;
}

.pay-bill {
    background-color: #deffd4;
}

.nopass {
    padding: 10px 0px;
    color: #ff4d4f
}