.lotto-section {
    margin: 10px;
    padding: 10px;
    background-color: #f4fcff;
    /* position: relative; */
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.lotto-card {
    /* position: relative; */
    padding: 10px;
    /* margin: 10px; */
    background-color: #fff;
    border: 1px solid #F8F2F3;
    border-radius: 5px;
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.lotto-card .header {
    padding: 5px;
    margin-bottom: 5px;
    /* background-color: #fff; */
    border: 1px solid #F8F2F3;
    border-radius: 5px;
    display: inline-flex;
    width: 100%;
    /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s; */
}

.lotto-card .content-card {
    /* padding: 10px 5px; */
    /* background-color: #fff; */
    border: 1px solid #F8F2F3;
    border-radius: 5px;
    padding: 5px;
    display: inline-flex;
    width: 100%;
    /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s; */
}

.lotto-card .time {
    /* padding: 10px 5px; */
    /* background-color: #fff; */
    border: 1px solid #F8F2F3;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    /* display: block; */
    width: 100%;
    /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s; */
}

.lotto-card .time .time-content {
    /* padding: 5px; */
    display: inline-flex;
    width: 100%;
}

.announce-section {
    margin: 10px;
    /* padding: 10px; */
    background-color: #f4fcff;
    /* position: relative; */
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.announce-section .announce-header {
    padding: 10px 10px 0px 20px;
    /* margin-bottom: 5px; */
    background-color: #4547FF;
    border: 1px solid #F8F2F3;
    border-radius: 5px 5px 0px 0px;
    /* display: block; */
    width: 100%;
}

.announce-section .announce-content {
    padding: 10px 10px 0px 20px;
    border: 1px solid #F8F2F3;
    /* border-radius: 0px 0px 5px 5px; */
    /* display: block; */
    width: 100%;
}